import type { Permission } from '@orus.eu/right-access-management'
import { isSubsetOf } from '@orus.eu/sets'
import { memo, type ReactNode } from 'react'
import { usePermissions } from '../../lib/use-permissions'
import { InviteToLoginPage } from './invite-to-login/InviteToLoginPage'

export const PermissionChecker = memo<{
  children: ReactNode
  requiredPermissions: Permission[]
}>(function PermissionChecker({ children, requiredPermissions }) {
  const { permissions } = usePermissions()

  if (!isSubsetOf(new Set(requiredPermissions), new Set(permissions))) {
    return <InviteToLoginPage />
  }

  return children
})
